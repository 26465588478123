.ant-modal-title {
  padding-left: 1rem;
}

.ant-select {
  width: 100%;
}

// .ant-modal-body {
//   padding-top: 0;
// }

.add-user {
  padding-left: 1rem;

  .row {
    .col-md-8 {
      padding-top: 2.5rem;
      padding-right: 2.5rem;

      .input-group {
        margin-bottom: 2.5rem;

        .ant-input {
          height: 2.25rem;
        }

        .ant-select {
          .ant-select-selector {
            height: 2.25rem;
          }

          .ant-select-arrow {
            color: #383838;
          }
        }
      }

      .grey-layout {
        background: #f8f9f8;
        border: 1px solid #d8d8d8;
        border-radius: 0.25rem;
        padding: 1.45rem 0.9rem;

        .col-md-6 {
          margin-bottom: 2rem;
          position: relative;
          padding-right: 0;

          .info-icon {
            position: absolute;
            transform: scale(0.75);
            cursor: pointer;
          }

          .ant-checkbox-wrapper {
            margin-right: 0.5rem;
          }
        }

        .col-md-6:nth-child(2) {
          padding-left: 0;
        }
      }
    }

    .col-md-4 {
      background: #f8f9f8;
      padding-top: 2rem;

      h6 {
        color: #383838;
        font-weight: bold;
        font-size: 0.875rem;
      }

      label {
        margin: 0.4rem 0;
        display: block;

        .ant-checkbox-wrapper {
          display: inline-block;
          margin-right: 0.5rem;
        }

        span {
          position: relative;

          .info-icon {
            transform: scale(0.75);
            position: absolute;
            right: -2.25rem;
            top: -0.2rem;
          }
        }
      }

      .border {
        margin: 0.5rem 0;
        border: none;
        height: 1px;
        background: #ddd;
      }
    }

    .button-container {
      width: 100%;
      padding-right: 2.5rem;
      padding-top: 2.5rem;

      .cancel {
        border: none;
        background: transparent;
        text-decoration: underline;
        font-size: 0.9rem;
        font-weight: bold;
        padding: 0.75rem 2.5rem;
      }

      .save {
        border: none;
        background: #5cc763;
        text-decoration: none;
        color: #fff;
        font-size: 0.9rem;
        font-weight: bold;
        padding: 0.75rem 2.5rem;
        border-radius: 0.25rem;
      }
    }
  }
}

.add-user-participant {
  padding-left: 1rem;

  .input-group {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;

    .field-name {
      font-size: 0.9rem;
      color: #5c5c5c;
    }

    .floating-label {
      height: 2.25rem;
      .ant-input,
      .floating-input {
        background: #ffffff;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        border-radius: 0.25rem;
        font-size: 1.125rem;
        height: 2.25rem;
        padding: 0px 0.9375rem;
      }

      .input-error {
        padding: 0.375rem 0rem;
        font-size: 0.9rem;
        color: red;
      }

      .react-tel-input {
        .form-control {
          height: 2.25rem;
          font-size: 1rem;
          box-sizing: border-box;
          width: 100%;
        }
      }
    }
  }
  .button-cancel {
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    text-decoration-line: underline;
    color: #767676;
  }

  .button-saved {
    padding: 0px 2rem;
    font-size: 0.9rem;
    height: 2.5rem !important;
  }
}

.button-cancel {
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  text-decoration-line: underline;
  color: #767676;
}

.button-saved {
  padding: 0px 2rem;
  font-size: 0.9rem;
  height: 2.5rem !important;
}
