.scores {
  margin: -2.5rem;
  padding-top: 80px;
  .score-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-name {
      .ant-input {
        background: #ffffff;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        border-radius: 0.25rem;
        font-size: 1.125rem;
        height: 3.1rem;
        width: 19.5rem;
        padding: 0px 0.9375rem;
      }
    }
  }

  .table-scores-container {
    padding: 25px;
    background: #f8f9fb;
    .ant-table {
      box-shadow: 0 1px 0.25rem rgba(0, 0, 0, 0.16);

      .ant-table-thead {
        background: #f8f9fb;

        .ant-table-cell {
          color: #757575;
          font-weight: bold;
        }
      }

      .ant-table-tbody {
        tr {
          td {
            font-style: normal;
            font-weight: normal;
            color: #373737;
          }
          // .ant-table-cell:nth-child(3) {
          //   span {
          //     min-width: 65px;
          //     text-align: center;
          //   }
          // }
        }
      }

      .ant-table-cell:nth-child(4) {
        text-align: right;
      }

      // .ant-table-cell:nth-child(3) {
      //   min-width: 65px;
      // }
    }

    .ant-table-pagination {
      display: none;
    }

    .table-name {
      display: flex;
      align-items: center;

      div:nth-child(1) {
        height: 3rem;
        width: 3rem;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        margin-right: 1rem;

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        .data-image {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #c0c0c0;
          color: #fff;
        }
      }

      div:nth-child(2) {
        h6 {
          font-size: 1rem;
          font-weight: normal;
          color: #373737;
          margin-bottom: 0.2rem;
        }

        p {
          margin: 0;
          font-size: 0.875rem;
          color: #757575;
        }
      }
    }
  }

  .pagination {
    margin: 2.5rem;

    > span {
      padding-top: 0.6rem;
      margin-left: 1.5rem;
      font-size: 0.875rem;
      color: #757575;
    }

    .ant-pagination {
      li {
        margin: 0;
        font-weight: bold;
        border-color: #eee;
        border-radius: 0;
        padding-top: 0.2125rem;
      }

      .ant-pagination-item {
        height: 2.5rem;
        width: 2.5rem;
      }

      .ant-pagination-item a {
        display: block;
        padding: 1px 6px;
        font-size: 0.875rem;
      }

      .ant-pagination-item-active {
        a {
          color: #fbb040;
        }
      }

      .ant-pagination-jump-next {
        min-width: 2.8rem;
        height: 2.2rem;
      }
      .ant-pagination-jump-prev {
        min-width: 2.8rem;
        height: 2.2rem;
      }

      .ant-pagination-prev {
        height: 2.5rem;
        width: 2.5rem;
        padding: 0;

        a {
          border-color: #eee;
          font-weight: bold;
          font-size: 0.8rem;
          padding: 1px 0px;
        }
      }

      .ant-pagination-next {
        height: 2.5rem;
        width: 2.5rem;
        padding: 0;

        a {
          border-color: #eee;
          font-weight: bold;
          font-size: 0.8rem;
          padding: 1px 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .scores {
  }
}
