.ant-picker {
  width: 100%;
}

.add-question {
  .ant-tabs {
    border: 2px solid #e6e6e6;
    .ant-tabs-content-holder {
      padding: 5px 10px !important;
      padding-bottom: 2rem !important;
    }
  }

  .row {
    .input-error {
      padding: 0.375rem;
      font-size: 0.9rem;
      color: $red-color;
    }

    .ant-picker {
      height: 2.25rem;
    }

    .first {
      padding-top: 2.5rem;
      padding-right: 1rem;

      .input-group {
        margin-bottom: 2rem;

        .input-error {
          padding: 0.375rem;
          font-size: 0.9rem;
          color: $red-color;
        }

        .ant-picker {
          height: 2.25rem;
        }
      }

      .input-group-less {
        margin-bottom: 2rem;
      }

      .grey-layout {
        .col-md-6 {
          margin-bottom: 2rem;
        }
      }
    }

    .button-container {
      width: 100%;

      .cancel {
        border: none;
        background: transparent;
        text-decoration: underline;
        font-size: 0.875rem;
        font-weight: bold;
        padding: 0.75rem 2.5rem;
      }

      .draft {
        border: 1px solid #d8d8d8;
        background: transparent;
        text-decoration: none;
        color: #5c5c5c;
        font-size: 0.875rem;
        font-weight: bold;
        padding: 0.75rem 2.5rem;
        border-radius: 0.25rem;
      }
    }
  }

  .field-array {
    padding: 1rem;
  }

  label {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .image-row {
    .image-container {
      height: 6.6rem;
      width: 10rem;
      border-radius: 0.25rem;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
      }
    }

    .image-container-view {
      height: 10rem;
      border-radius: 0.25rem;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
      }
    }

    .image-wrapper {
      background: #e0e0e0;
      height: 6.6rem;
      border-radius: 0.25rem;
      position: relative;
    }

    .upload-wrapper {
      display: flex;
      height: 2.625rem;
      align-items: stretch;
      margin-top: auto;

      .ant-input {
        width: auto;
        flex: 1;
        height: 2.625rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .upload-button {
        border-radius: 0px;
        border: 1px solid $primary-color;
        color: $primary-color;
        background: white;
        padding: 0.63rem 0.75rem;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        font-size: 0.825rem;
      }
    }
  }
}
