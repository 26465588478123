.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: calc(100% - 80px);
  height: $navbar-height;
  position: fixed;
  left: 80px;
  top: 0;

  background: #fff;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
  z-index: 888;

  .actions {
    display: flex;
    margin-left: auto;
    padding: 0 0 0 20px;
    align-items: center;
    justify-content: flex-end;
    height: $navbar-height;
    .action-button {
      padding: 1.25rem;
    }
    .notification {
      margin-right: 0.6rem;

      .image-container-nav {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 5rem;
        position: relative;
        .ant-avatar {
          display: inline-block;
          height: 100%;
          width: 100%;
          vertical-align: top;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 5rem;
        }
      }
    }
  }
}

.user {
  height: 100%;
  background-color: $color-nav-light;
  padding: 1rem;
  display: flex;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0.8rem 0.675rem;

  li {
    color: #5c5c5c;
    font-size: 1rem;
  }
}

.notification-dropdown {
  width: 600px;
  .ant-popover-inner {
    padding: 16px;
    border-radius: 16px;
    .ant-popover-inner-content {
      width: 100%;
      min-width: 500px;
    }
    .ant-popover-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;
      padding: 0px;
      border-bottom: none;
      padding: 12px;
    }
    .notification-item {
      cursor: pointer;
      padding: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      color: $text-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      overflow: hidden;
      .notification-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        min-width: 0px;
      }
      &.read {
        background-color: $bg-background;
        .notification-text {
          color: $text-gray;
        }
      }
    }
    .notification-pagination {
      &.ant-pagination {
        margin: 0px;
        padding: 12px;
      }
    }
    .no-notification-found {
      width: 100%;
      height: 100px;
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
      color: $text-gray;
    }
  }
}
