.role-selector-custom {
  .ant-radio-button-wrapper {
    border-radius: $border-m;
    height: $input-height;
    line-height: $input-height;
    width: 120px;
    text-align: center;
    &:hover {
      color: $primary-color;
    }
  }

  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper {
    border: solid 1px $primary-color;
    color: $primary-color;
    font-weight: 600;
  }
  .ant-radio-button-wrapper:not(:first-child) {
    border-left-width: 1px;

    &:before {
      content: none;
    }
  }
}

.btn-block {
  width: 100%;
  height: $input-height;
  border-radius: $border-m;
}
.primary-background {
  background-color: $primary-color;
  border-color: $primary-color;
  color: #fff;
  font-weight: 500;
  &:hover,
  &:focus {
    color: $primary-color;
    border-color: $primary-color;
    .add-white-icon {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgNlYxQzYgMC40NDc3MTUgNi40NDc3MiAwIDcgMEM3LjU1MjI4IDAgOCAwLjQ0NzcxNSA4IDFWNkgxM0MxMy41NTIzIDYgMTQgNi40NDc3MiAxNCA3QzE0IDcuNTUyMjggMTMuNTUyMyA4IDEzIDhIOFYxM0M4IDEzLjU1MjMgNy41NTIyOCAxNCA3IDE0QzYuNDQ3NzIgMTQgNiAxMy41NTIzIDYgMTNWOEgxQzAuNDQ3NzE1IDggMCA3LjU1MjI4IDAgN0MwIDYuNDQ3NzIgMC40NDc3MTUgNiAxIDZINloiIGZpbGw9IiMwMGI5ZmMiLz4KPC9zdmc+Cg==");
    }
  }
}

.btn-outline-primary {
  border: solid 1px $primary-color;
  color: $primary-color;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px 0.625rem;
  cursor: pointer;
  &:hover {
    background-color: $primary-color;
    color: $white;
    border: solid 1px $primary-color;
  }
}
.btn-success {
  background-color: $green-color;
  border-color: $green-color;
  color: #fff;
  font-weight: 500;
  &:hover,
  &:focus {
    color: $green-color;
    border-color: $green-color;
  }
}

.secondary-background {
  background-color: $color-input-border;
  border-color: $color-input-border;
  color: #fff;
  font-weight: 500;
  height: 42px;
  &:hover,
  &:focus {
    color: $color-input-border;
    border-color: $color-input-border;
  }
}

.custom-button.btn-lg {
  font-size: 1rem;
  height: 2.875rem;
  font-weight: normal;
  font-size: 14px;
  font-weight: 500;
}
.button-cancel {
  text-decoration: underline;
  color: $color-gray-1;
  font-weight: 500;
}

.button-group {
  box-shadow: 0px 0px 1px rgb(0 0 0 / 16%);
  border-radius: 6px;
  background-color: #fff;
  &.btn-lg {
    height: 3.875rem;
  }
}
.upload-button {
  border-radius: 0px;
  border: 1px solid $primary-color;
  color: $primary-color;
  background: white;
  padding: 0.63rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 0.825rem;
}
