@font-face {
  font-family: "poppins";
  src: url("../assets/fonts/poppins-regular-webfont.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "poppins";
  src: url("../assets/fonts/poppins-bold-webfont.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "poppins";
  src: url("../assets/fonts/poppins-light-webfont.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "poppins";
  src: url("../assets/fonts/poppins-medium-webfont.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "poppins";
  src: url("../assets/fonts/poppins-semibold-webfont.ttf") format("truetype");
  font-weight: 600;
}
