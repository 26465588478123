.input-container {
  input,
  select {
    height: $input-height;
    line-height: $input-height;
    border-radius: $border-m;
    border: solid 1px $color-input-border;
  }
  .ant-select-selection-item {
    line-height: $input-height;
  }
  .input-password .ant-input-affix-wrapper {
    padding: 0;
    border: solid 1px $color-input-border;
    border-radius: $border-m;
    input {
      padding: 0 11px;
    }
    .ant-input-suffix {
      margin-right: 11px;
    }
  }
}
.tel-input {
  .react-tel-input .form-control {
    height: $input-height;
    line-height: $input-height;
    border-radius: $border-m;
    border: solid 1px $color-input-border;
    width: 100%;
  }
}
.tel-input-sm {
  .react-tel-input .form-control {
    height: $input-height-sm;
    line-height: $input-height-sm;
    border-radius: $border-m;
    border: solid 1px $input-border-color;
    width: 100%;
  }
}
.filter {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: $border-m;
  }
}
.searchInput-mini {
  .ant-input-group-addon {
    width: 0;
  }
}
.image-wrapper-l {
  background: #e0e0e0;
  height: 9.5rem;
  width: 9.5rem;
  border-radius: 5rem;
  position: relative;
  object-fit: cover;
}
.add-project-owner {
  .therapist-selector {
    .ant-select-selector {
      height: 38px;
      border-radius: 0.25rem;
      .ant-select-selection-item {
        padding-top: 5px;
      }
    }
  }
}
.form-label {
  font-weight: 500;
}

.action-dropdown {
  border-radius: $border-m;
  background-color: $white;
  box-shadow: 0px 0px 4px #ddd;
  .ant-dropdown-menu-item:hover .ant-dropdown-menu-title-content {
    color: $primary-color;
  }
}
.ant-dropdown-menu-item.text-danger .ant-dropdown-menu-title-content {
  color: $red-color;
}
.ant-radio span,
.ant-radio-group span {
  font-weight: 500;
}

.floating-label {
  .ant-picker {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-picker-input input {
    height: $input-height-sm;
  }
}
.floating-label {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: $input-height-sm;
    input {
      height: $input-height-sm;
    }
    .ant-select-selection-placeholder {
      line-height: $input-height-sm;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: $input-height-sm;
  }
}

.ant-radio-checked::after {
  border-color: $primary-color;
}
.ant-radio-inner::after {
  background-color: $primary-color;
}
.ant-select-arrow {
  span {
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
    border-top: solid 7px #383838;
    svg {
      display: none;
    }
  }
}
.ant-dropdown-menu-item {
  font-weight: 500;
}
.navigation-crumb {
  .ant-breadcrumb-link {
    text-decoration: none;
    color: $primary-color;
    font-weight: 500;
  }
}
