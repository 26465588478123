.cms-wrapper {
  margin: -2.5rem;
  padding-top: 80px;
  .ant-tabs-nav {
    padding: 0px 16px;
    height: 60px;
    background-color: #ffffff;
    margin-bottom: 0px;
    .ant-tabs-tab-btn {
      font-weight: 500;
    }
  }

  .modules-wrapper {
    padding: 24px;
    overflow: auto;
    height: calc(100vh - 200px);
    &.question-module-wrapper {
      height: calc(100vh - 140px);
    }
    .modules-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      height: 40px;
    }
  }

  .modules-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(433px, 1fr));
    grid-gap: 20px;
    .singer-card {
      padding: 16px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 1px 1px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      .ant-avatar {
        border-radius: 4px;
      }
      .education-details {
        margin-left: 16px;
        flex: 1;
        min-width: 1px;
        .medium-header-text {
          font-size: 20px;
          font-weight: 600;
          line-height: 150%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .pages {
          font-size: 16px;
          line-height: 150%;
          color: #4b5563;
          margin-bottom: 10px;
        }
        &.education-card {
          .pages {
            margin-bottom: 0px;
          }
        }
        .status-tag {
          width: fit-content;
          border-radius: 6px;
          height: 26px;
          padding: 4px;
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
          color: $primary-color;
          border: 1px solid $primary-color;
        }
        .status-tag.draft {
          color: $text-gray;
          background: $border-color;
          border-color: $border-color;
        }
        .tag-container {
          display: flex;
          flex-wrap: wrap;
          .status-tag {
            margin-top: 10px;
          }
        }
      }
      .optionsIcon {
        margin-left: auto;
      }
    }
  }
}

.learning-module-wrapper {
  margin: -2.5rem;
  padding-top: 80px;
  .learning-module-content {
    padding: 24px;
    overflow: auto;
    height: calc(100vh - 140px);
  }
}

.no-match-found {
  width: 100%;
  height: 50vh;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 150%;
  color: $text-gray;
}

.singer-drag-drop.ant-upload-drag {
  background: #f9fafb;
  border: 2px dashed $border-color;
  border-radius: 20px;
  height: 125px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #374151;
  &.small {
    height: 44px;
    width: 100px;
    .preview-image {
      height: 40px;
    }
  }
  &:not(.ant-upload-disabled):hover {
    border-color: $primary-color;
  }
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
  .ant-upload-drag-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .preview-image {
    object-fit: contain;
    height: 120px;
  }
}
