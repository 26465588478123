.delete-account-wrapper {
  height: calc(100vh - 143px);
  overflow: auto;
  background-color: #fff;

  .policy-container {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.07);
    padding: 80px 100px;
    height: 100%;
    overflow: auto;
  }

  .policy-bordered-container {
    border: 1px solid #aaacaa;
    border-radius: 16px;
    padding: 45px;
    min-height: 100%;
  }

  .flex-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .title {
    font-size: 44px;
    font-weight: 600;
    color: #111928;
    margin-bottom: 100px;
  }

  .subtitle {
    font-size: 37px;
    font-weight: 500;
    color: #111928;
    margin-bottom: 50px;
  }

  .info {
    font-size: 26px;
    font-weight: 400;
    color: #111928;
    display: list-item;
    list-style: inside;
    list-style-type: disc;
    max-width: 657px;
  }

  @media (max-width: 780px) {
    .title {
      font-size: 34px;
    }
    .policy-container,
    .policy-bordered-container {
      padding: 15px;
    }
  }
}
