.user-filter {
  background: #fff;
  box-shadow: 0px 1px 0.25rem rgba(0, 0, 0, 0.16);
  border-radius: 0.25rem;
  // height: 100%;
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;

  .control-intervention-group {
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 1.1rem;
      color: #333333;
    }
  }

  h6 {
    padding: 1.25rem;
    padding-bottom: 0;
    color: #545454;
    font-weight: bold;
  }

  &-row {
    padding: 1.25rem;
    border-bottom: 1px solid #e5e5e5;
    // flex: 1;
    // height: calc(100vh - 270px);
    overflow-y: auto;

    .input-pre-group {
      margin-bottom: 1.25rem;
      label {
        font-size: 0.875rem;
        font-weight: bold;
      }

      .ant-select {
        .ant-select-selector {
          height: 2.25rem;
          .ant-select-selection-item {
            font-size: 1rem;
          }
        }

        .ant-select-arrow {
          color: #383838;
        }
      }
    }

    &-fields {
      .input-group {
        margin-bottom: 1.25rem;

        label {
          font-size: 0.875rem;
          font-weight: bold;
        }

        .ant-select {
          .ant-select-selector {
            height: 2.25rem;
            .ant-select-selection-item {
              font-size: 1rem;
            }
          }

          .ant-select-arrow {
            color: #383838;
          }
        }

        .response-info {
          display: flex;
          align-items: center;

          .info-icon {
            margin-left: 1.5rem;
            margin-top: 0.5rem;
          }
        }
      }

      .response {
        label {
          display: block;
        }

        > div {
          width: 100%;

          .ant-select {
            width: 30%;
          }
        }
      }

      .condition {
        &-wrapper {
          width: 100%;
          display: flex;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            border: 1px solid #d8d8d8;
            border-radius: 0.25rem;
            margin-right: 0.75rem;
            padding: 0.5rem 0.75rem;
            color: #5c5c5c;
            font-size: 0.875rem;
          }

          .selected {
            color: #00b9fc;
            border-color: #00b9fc;
          }
        }
      }

      .score-conditions {
        display: flex;
        align-items: center;
        padding: 0rem 0.25rem;
        margin-bottom: 0.7rem;

        > span {
          font-style: normal;
          font-weight: bold;
          font-size: 0.9rem;
          color: #333333;
        }

        .floating-select {
          .ant-select {
            .ant-select-selector {
              width: 16rem;
              height: 2.5rem;
              padding: 4px 1rem;
              .ant-select-selection-item {
                font-size: 1.05rem;
                margin-right: 5px;
                font-style: normal;
                font-weight: 500;
                color: #000000;
                .ant-select-selection-item-content {
                }
                .ant-select-selection-item-remove {
                  margin: -3px 0px;
                  margin-bottom: 4px;
                }
              }
            }
            .ant-select-arrow {
              color: #383838;
            }
          }
        }

        .floating-between {
          .ant-select {
            .ant-select-selector {
              width: 14rem;
              height: 2.5rem;
              padding: 4px 1rem;
              .ant-select-selection-item {
                font-size: 1.05rem;
                margin-right: 5px;
                font-style: normal;
                font-weight: 500;
                color: #000000;
                .ant-select-selection-item-content {
                }
                .ant-select-selection-item-remove {
                  margin: -3px 0px;
                  margin-bottom: 4px;
                }
              }
            }
            .ant-select-arrow {
              color: #383838;
            }
          }
        }

        .between-inputs-text {
          font-style: normal;
          font-weight: bold;
          font-size: 0.9rem;
          color: #333333;
          margin: 0rem 0.5rem;
        }

        .input-name {
          .ant-input {
            background: #ffffff;
            border: 1px solid #dbdbdb;
            box-sizing: border-box;
            border-radius: 0.25rem;
            font-size: 1.125rem;
            height: 2.5rem;
            width: 5rem;
            padding: 0px 0.9375rem;
            color: #000000;
          }
        }
      }
    }
  }
}

.user-filter-button-container {
  .grey-btn {
    background: transparent !important;
    text-decoration: underline;
    color: #767676 !important;
    border: none;
    font-size: 1rem;
  }
}

.ant-tooltip-inner {
  background: #eaeaea;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 0.375rem;
}

.ant-tooltip-arrow::before {
  background: transparent;
}

@media only screen and (max-width: 1024px) {
  .user-filter {
    &-row {
      &-fields {
        .score-conditions {
          display: flex;
          flex-direction: column;
          align-items: flex-start !important;

          .floating-select {
            margin: 1rem 0rem !important;
            .ant-select {
              .ant-select-selector {
                width: 11.3rem;
                height: 2.5rem;
                padding: 4px 1rem;
                .ant-select-selection-item {
                  font-size: 1.05rem;
                  margin-right: 5px;
                  font-style: normal;
                  font-weight: 500;
                  color: #000000;
                  .ant-select-selection-item-content {
                  }
                  .ant-select-selection-item-remove {
                    margin: -3px 0px;
                    margin-bottom: 4px;
                  }
                }
              }
              .ant-select-arrow {
                color: #383838;
              }
            }
          }

          .floating-between {
            margin: 1rem 0rem !important;
            .ant-select {
              .ant-select-selector {
                width: 11.3rem;
                height: 2.5rem;
                padding: 4px 1rem;
                .ant-select-selection-item {
                  font-size: 1.05rem;
                  margin-right: 5px;
                  font-style: normal;
                  font-weight: 500;
                  color: #000000;
                  .ant-select-selection-item-content {
                  }
                  .ant-select-selection-item-remove {
                    margin: -3px 0px;
                    margin-bottom: 4px;
                  }
                }
              }
              .ant-select-arrow {
                color: #383838;
              }
            }
          }

          .between-inputs-text {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            color: #333333;
            margin: 0rem 0.4rem;
          }
        }
      }
    }
  }
}
