.singer-custom-tab,
.singer-tabs-color {
  padding: 2px;
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary-color;
  }
  .ant-tabs-tab {
    color: #37373799;
    margin-right: 1.25rem;
  }
  .ant-tabs-ink-bar {
    background-color: $primary-color;
  }
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: $primary-color;
  }
}
.singer-custom-tab {
  .ant-tabs-nav {
    background: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    margin-bottom: 2rem;
    padding: 0 1.25rem;
    font-weight: 500;
    border-radius: $border-m;
  }
  @media (max-width: 767px) {
    .ant-table-content {
      overflow-x: scroll;
    }
  }
}
