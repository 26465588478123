.dashboard-card {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 24px;
  .title {
    font-size: 30px;
    line-height: 150%;
    margin-bottom: 60px;
  }
  &.completion-rate {
    .completion-rate-item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      .progress-label {
        width: 170px;
      }
      .completion-rate-progress-bar {
        .ant-progress-inner {
          border-radius: 6px;
        }
        .ant-progress-bg {
          border-radius: 6px;
          height: 20px !important;
        }
        .ant-progress-text {
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: $text-color;
          margin-left: 16px;
        }
      }
    }
  }
}
.main-dashboard-container {
  .study-metric-container {
    display: flex;
    margin-bottom: 16px;
  }
  .ecg {
    max-width: 50%;
    flex: 1;
    .no-graph-data {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;
    }
    .graph-container {
      display: flex;
      justify-content: space-between;
      .donut-graph-wrapper {
        flex: 1;
        max-width: 50%;
        min-width: 0px;
        height: 300px;
        position: relative;
        .chart-center-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          .value {
            font-weight: 600;
            font-size: 22px;
            line-height: 18px;
            color: #333333;
          }
          .label {
            font-size: 14px;
            line-height: 22px;
            color: #4f4f4f;
            margin-bottom: 5px;
          }
        }
      }
      .donut-label-wrapper {
        flex: 1;
        min-width: 0px;
        max-width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 25px;
        .donut-chart-legend {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .point {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 12px;
          }
          .label {
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: $text-color;
            flex: 1;
            min-width: 0px;
          }
          .divider {
            background-color: #c4c4c4;
            height: 12px;
            width: 1px;
            margin-right: 15px;
          }
          .value {
            font-size: 14px;
            line-height: 22px;
            color: $text-color;
          }
        }
      }
    }
  }
  .study-metric {
    max-width: 50%;
    margin-right: 16px;
    flex: 1;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    .study-metric-item {
      min-height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .metric-detail {
        font-size: 30px;
        line-height: 150%;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-progress-text {
          font-weight: bold;
          font-size: 18px;
          line-height: 150%;
          text-align: center;
          color: $text-color-2;
        }
      }
      .metric-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: $text-color-2;
      }
    }
  }
}
