.builder-shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.edit-study-layout {
  margin: -2.5rem;
  height: calc(100vh - 60px);
  width: calc(100vw - 80px);
  padding-top: 80px;
  background-color: #fafafa;
  .division {
    flex: 1;
    min-width: 0px;
    border-radius: 12px;
    background-color: white;
    .division-header {
      height: 64px;
      border-bottom: 1px solid $border-color;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      position: sticky;
      z-index: 10;
      top: 0;
    }
    .builder-block {
      display: flex;
      margin: 0px 16px;
      height: 48px;
      align-items: center;
      border-bottom: 1px solid $border-color;
      color: $text-color;
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
    .builder-block.selected {
      color: $primary-color;
    }
    .no-phase-found {
      color: $text-color;
      font-weight: 600;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
      width: 100%;
    }
  }
}
