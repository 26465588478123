.singer-custom-table {
  // box-shadow: 0 1px 0.25rem rgba(0, 0, 0, 0.16);
  // padding: 2px;
  .ant-table {
    box-shadow: 0 1px 0.25rem rgba(0, 0, 0, 0.16);
    border-radius: $border-l;

    .ant-table-thead {
      > tr > th,
      .ant-table-tbody > tr > td,
      .ant-table tfoot > tr > th,
      .ant-table tfoot > tr > td {
        padding: 0.7rem 1rem;
      }
      tr:first-child th:first-child {
        border-top-left-radius: $border-l;
      }
      tr:first-child th:last-child {
        border-top-right-radius: $border-l;
      }
      th {
        background: $bg-thead;
        white-space: nowrap;
        font-size: 0.875rem;
      }
      .ant-table-cell {
        color: $color-gray1;
        font-weight: 600;
      }
    }

    .ant-table-tbody {
      tr {
        td {
          font-style: normal;
          font-weight: normal;
          color: $table-body;
          font-size: 0.875rem;
          line-height: 1.315rem;
          font-weight: 400;
          // background-color: $white;
        }
      }
    }

    // .ant-table-cell:nth-child(6) {
    //   text-align: right;
    // }
  }
}

.dashboard-table {
  .ant-table-thead {
    th {
      background: #fff;
    }
  }
}
.dashboard-table-view {
  .ant-table-thead {
    tr > th {
      background: #fff;
      border-bottom: none;
      padding: 0;
      > div {
        border-left: 1px solid #e0e0e0;
        padding: 20px;
        margin-bottom: 10px;
      }
      svg {
        min-height: 25px;
      }
    }
  }
  tr > td {
    border-left: 1px solid #e0e0e0;
  }
  table {
    border-collapse: unset;
  }
}
