.ant-modal-header {
  border-radius: 1.25rem 1.25rem 0 0;
  padding: 1.375rem 1.875rem;
  .ant-modal-title {
    font-size: 1.25rem;
    font-weight: 500;
  }
}
.anticon.ant-modal-close-icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgNS40NDQ2OUwxMi40NDQ3IDBMMTQgMS41NTUzMUw4LjU1NTMxIDdMMTQgMTIuNDQ0N0wxMi40NDQ3IDE0TDcgOC41NTUzMUwxLjU1NTMxIDE0TDAgMTIuNDQ0N0w1LjQ0NDY5IDdMMCAxLjU1NTMxTDEuNTU1MzEgMEw3IDUuNDQ0NjlaIiBmaWxsPSIjQjJCMkIyIi8+Cjwvc3ZnPgo=);
  svg {
    display: none;
  }
  width: 0.9375rem;
  height: 0.9375rem;
  background-repeat: no-repeat;
  display: inline-block;
}
.ant-modal-close {
  top: 6px;
  right: 10px;
}
.ant-modal-mask {
  background-color: rgba(24, 24, 27, 0.2);
}
.ant-modal-content {
  border-radius: $border-l;
  box-shadow: 0px 0px 40px rgba(118, 118, 118, 0.5);
}
.ant-modal-body {
  padding: 1.875rem;
  .button-cancel {
    margin-right: 1.875rem;
  }
  .custom-button {
    min-width: 7.0625rem;
    height: 2.625rem;
    border-radius: $border-m;
  }
}
