.search-input {
  border: 0;
  padding: 0.75rem !important;
  // width: 17.2rem;
  border-radius: 0.25rem;
  box-shadow: 0 1px 0.25rem rgba(0, 0, 0, 0.16);
  outline: none;

  .ant-input {
    font-size: 1rem;
    border: none;
    background-color: inherit;
  }
  .ant-input:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none;
    box-shadow: none !important;
  }
  .ant-input::placeholder {
    color: #757575;
    font-size: 1rem;
  }
  .ant-input-group {
    outline: none;
  }
  .ant-input-group-addon {
    .ant-input-search-button {
      display: none;
    }
  }
}
.ant-select {
  width: 100%;
}
.primary-color {
  color: $primary-color;
}
// @media only screen and (max-width: 1024px) {
//   .search-input {
//     width: 17.2rem;
//   }
// }

// @media only screen and (max-width: 768px) {
//   .search-input {
//     width: 15.2rem;
//   }
// }

.searchInput-mini {
  .ant-input-search.search-input {
    padding: 5px 0.625rem !important;
    background-color: $white;
  }
}
.select-mini {
  .ant-select-selector {
    padding: 0 !important;
  }
}
