.user-actions {
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .document-sort {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
  }
  .document-sort-select {
    width: 200px;
    .ant-select-selector {
      border-color: $primary-color;
      background-color: transparent;
    }
  }
}
