%sidebar-icon {
  background-position: center;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

%qb-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 5px;
}

.info-icon {
  background-image: url("../../assets/img/info-icon.svg");
  @extend %sidebar-icon;
}
.help-icon {
  background-image: url("../../assets/img/info-icon.svg");
  @extend %sidebar-icon;
}
.settings-circle-icon {
  background-image: url("../../assets/img/settings-icon.svg");
  @extend %sidebar-icon;
}
.more-icon {
  background: url("../../assets/img/more.svg");
  @extend %sidebar-icon;
}
.cross-icon {
  background: url("../../assets/img/failure-cross.svg");
  @extend %sidebar-icon;
}
.options-section {
  background: url("../../assets/img/options-section.svg");
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 0.625rem;
  height: 1.375rem;
  display: inline-block;
}

%sidebar-icon-m {
  background-position: center;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 2rem;
  height: 2rem;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.A-article {
  background: url("../../assets/img/A-article.svg");
  @extend %sidebar-icon-m;
}

.A-question {
  background: url("../../assets/img/A-question.svg");
  @extend %sidebar-icon-m;
}
.A-pictures {
  background: url("../../assets/img/A-pictures.svg");
  @extend %sidebar-icon-m;
}

.A-resources {
  background: url("../../assets/img/A-resources.svg");
  @extend %sidebar-icon-m;
}

.A-title {
  background: url("../../assets/img/A-title.svg");
  @extend %sidebar-icon-m;
}

.A-video {
  background: url("../../assets/img/A-video.svg");
  @extend %sidebar-icon-m;
}
.video-section {
  background: url("../../assets/img/video-section.svg");
  @extend %sidebar-icon-m;
}
.arrow-left-icon {
  background: url("../../assets/img/arrow-left.svg");
  @extend %sidebar-icon;
  width: 1rem;
  height: 1rem;
}
.user-icon-img {
  background-image: url("../../assets/img/user.svg");
  @extend %sidebar-icon;
}
.add-white-icon {
  display: inline-block;
  width: 26px;
  height: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/plus-icon.svg");
  top: -2px;
  position: relative;
}
.user-icon-text {
  background-color: $icon-bg;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.primary-icon {
  color: $primary-color;
}
.delete-green-icon {
  background: url("../../assets/img/delete-green.svg");
  @extend %sidebar-icon;
  width: 1.5rem;
  height: 1.5rem;
}
.add-green-icon {
  background: url("../../assets/img/add-green.svg");
  @extend %sidebar-icon;
  width: 1.5rem;
  height: 1.5rem;
}

.arrow-up-icon {
  background: url("../../assets/img/arrow-up.svg");
  @extend %sidebar-icon;
  background-size: contain !important;
  width: 0.5rem;
  height: 0.5rem;
}

.arrow-down-icon {
  background: url("../../assets/img/arrow-down.svg");
  @extend %sidebar-icon;
  background-size: contain !important;
  width: 0.5rem;
  height: 0.5rem;
}

.score-add-icon {
  background: url("../../assets/img/score-add-icon.svg");
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 0.9rem;
  height: 0.9rem;
  display: inline-block;
  vertical-align: middle;
}
.score-divide-icon {
  background: url("../../assets/img/score-divide-icon.svg");
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 0.9rem;
  height: 0.9rem;
  display: inline-block;
  vertical-align: middle;
}

.score-multiply-icon {
  background: url("../../assets/img/score-multiply-icon.svg");
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 0.9rem;
  height: 0.9rem;
  display: inline-block;
  vertical-align: middle;
}

.score-subtract-icon {
  background: url("../../assets/img/score-subtract-icon.svg");
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 1rem;
  height: 0.2rem;
  display: inline-block;
  vertical-align: middle;
}
.add-score {
  background: url("../../assets/img/add-score.svg");
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.remove-score {
  background: url("../../assets/img/remove-score.svg");
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 1rem;
  height: 0.2rem;
  display: inline-block;
  vertical-align: middle;
}
.profile-m {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background-size: contain;
  flex: 0 0 3.75rem;
}
.add-blue-icon {
  background: url("../../assets/img/add-blue.svg");
  @extend %sidebar-icon;
  width: 1.5rem;
  height: 1.5rem;
  &.icon-sm {
    width: 1rem;
    height: 1rem;
  }
}

.image-icon {
  background: url("../../assets/img/png-icon.svg");
  @extend %sidebar-icon;
}
.pdf-icon {
  background: url("../../assets/img/pdf-icon.svg");
  @extend %sidebar-icon;
}
.csv-icon {
  background: url("../../assets/img/csv-icon.svg");
  @extend %sidebar-icon;
}
.folder-icon {
  background: url("../../assets/img/folder-icon.svg");
  @extend %sidebar-icon;
}
.thumbs-up {
  background: url("../../assets/img/thumbsUp.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat !important;
  width: 8rem;
  height: 8rem;
  display: inline-block;
}
.pre-bookmarked-icon {
  background: url("../../assets/img/pre-bookmarked.svg");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 1.8rem;
  height: 1.8rem;
  display: inline-block;
  vertical-align: middle;
}

.post-bookmarked-icon {
  background: url("../../assets/img/post-bookmarked.svg");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 1.8rem;
  height: 1.8rem;

  display: inline-block;
  vertical-align: middle;
}

.add-blue-bg-icon {
  background: url("../../assets/img/add-blue-bg.svg");
  @extend %sidebar-icon;
  width: 1.625rem;
  height: 1.625rem;
}

.error-small-icon {
  background: url("../../assets/img/Error.svg");
  @extend %sidebar-icon;
  width: 1.5rem;
  height: 1.5rem;
}
