.score-preview {
  .score-preview-body {
    .score-preview-questions {
      overflow-y: auto;
      height: 35rem;
      border-right: 2px solid #eeeeee;
      .score-preview-q {
        background: #ffffff;
        border-radius: 0.125rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        padding: 0.8rem;
        color: #000000;
        .question-number {
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          color: #545454;
        }
        .question-title {
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          color: #000000;
        }
      }
    }
    .score-preview-data {
      .score-preview-exp {
        padding-bottom: 1.5rem;
        border-bottom: 2px solid #eeeeee;

        .score-box {
          background: #ffffff;
          border-radius: 4px;
          padding: 1rem 0rem;

          .score-x {
            margin: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            color: #000000;
          }

          .question-input {
            border: none;
            background: transparent;
            outline: none;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            color: #30d073;
            width: 43px;
            text-align: center;
          }
          .operator-input {
            border: none;
            background: transparent;
            outline: none;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            width: 16px;
            color: #eb5757;
            text-align: center;
          }
          .const-input {
            border: none;
            background: transparent;
            outline: none;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            width: 3rem;
            color: #333333;
            text-align: center;
          }
        }
      }

      .score-preview-conditions {
        // overflow-y: auto;
        // height: 22rem;

        h5 {
          margin: 1.5rem 0rem 1rem 0rem;
        }
        .score-conditions {
          background: #ffffff;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          color: #000000;
          margin-top: 3rem;

          .score-border {
            border: 2px solid grey;
            padding: 1rem;
          }

          .score-border-cond {
            border: 2px solid grey;
            padding: 1rem;
            width: 230px;
            text-align: center;
          }
        }
      }
    }
  }
}
