.calendar-availability-modal{
    .available-fields-wrapper{
        display: flex;
        .available-field{
            .label{
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #5C5C5C;
            }
            .input-field{
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #373737;
            }
            .input-field.ant-select{
                width: 100%;
                .ant-select-selector{
                    height: 36px;
                }
            }
            .input-field:not(.ant-select){
                height: 36px;
                border: 1px solid #DBDBDB;
                border-radius: 4px;
                padding: 0px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .available-field:nth-child(2){
            margin: 0px 20px;
        }
    }
}
.modal-action-container{
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}