.question-builder {
  margin: -2.5rem;
  padding-top: 80px;
  .sub-header {
    .title-form {
      margin-top: 2.5rem;
      color: #545454;
      font-size: 1.25rem;
      font-weight: bold;
      text-transform: capitalize;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      color: #a0a5b8;
      width: 50%;
    }

    .upload-button {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(135, 136, 249, 0.4);
      border-radius: 4px;
      font-weight: 500;
      font-size: 1rem;
      color: $primary-color;
      border: 2px solid $primary-color;
    }
  }

  .builder-interface {
    padding: 20px;
    display: flex;
    position: relative;
    .question-list {
      flex: 1;
      min-width: 0px;
      margin-left: 20px;
    }

    .drop-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 33vh;
      border: 2px dashed #bbb;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      padding: 50px;
      background: rgb(248, 249, 251);
      #dropZone {
        text-align: center;
        font: 21pt bold arial;
        color: #bbb;
      }
    }

    .choices {
      position: sticky;
      top: 160px;
      width: 300px;
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      max-height: calc(100vh - 200px);
      overflow: hidden;
      &:hover {
        overflow: auto;
      }
      .choice-items {
        .grid-choice {
          padding-bottom: 1rem;

          .choice-item {
            display: flex;
            align-items: center;
            border: 1px solid $border-color;
            border-radius: 10px;
            padding: 10px 16px;
            cursor: grab;

            span {
              margin-left: 10px;
              color: #18181b;
              font-size: 16px;
              line-height: 24px;
              font-weight: 500;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          @media (max-width: 767px) {
            top: 0;
            width: auto;
            .choice-item {
              width: auto;
              .multichoice-icon {
                margin-right: 0;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }

    .choice-buttons {
      width: 100%;
      margin: 2.5rem 0;

      .choice-check-box {
        margin-bottom: 1rem;
        > span {
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          color: #5c5c5c;
          margin-left: 0.6rem;
        }
      }

      button[type="button"] {
        height: 3rem;
        padding: 0.5rem 1rem;
      }

      .grey-button {
        background: #ddd !important;
        color: #5c5c5c !important;
      }
    }

    .grey-layout {
      background-color: $bg-background;
      border: 1px solid $input-border-color;
      // background-color: #f8f9fb;
      // border: 1px solid #dbdbdb;
      border-radius: 4px;
      // padding: 1rem 5.375rem 1rem 1.375rem;
      // padding: 2rem;
      // position: fixed;
      // top: 18%;
      // margin-right: 40px;
      @media (max-width: 767px) {
        margin-bottom: 50px;
      }
    }
    .question-picture{
      position: relative;
      .picture-clear{
        width: 15px;
        height: 15px;
        position: absolute;
        top: 4px;
        right: 4px;
      } 
    }
    .question-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.question-settings-modal {
  .ant-modal-content {
    border-radius: 16px;
    box-shadow: none;
    .ant-modal-body {
      padding: 20px;
      .ant-tabs-tab {
        font-weight: 600;
        color: $text-gray;
      }
    }
    .response-choice-selector {
      .ant-select-selector {
        border-radius: 16px;
      }
    }
    .logic-jump-datepicker {
      border-radius: 16px;
      height: 40px;
    }
    .branch-container {
      padding-bottom: 20px;
      border-bottom: 1px solid $border-color;
    }
    .grid-preview {
      display: block;
      overflow: auto;
      td,
      th {
        min-width: 200px;
        border: 1px solid $border-color;
        text-align: center;
        height: 50px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .question-builder {
    .builder-interface {
      .choices {
        padding: 0rem;
      }
      .grey-layout {
        padding: 1rem 1.375rem;
        // margin-right: 7px;
      }

      .choice-buttons {
        margin: 4rem 0;
        button[type="button"] {
          height: 2.5rem;
          padding: 5px 10px;
        }
      }
    }
  }
}
