.questions {
  background: #F8F9FB;
  min-height: 100vh;
  margin-left: 15.625rem;
  flex: 1;

  .question-actions {
    margin: 2.5rem 0;
    // background: #F8F9FB;
    padding: 0 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-fields {
      display: flex;
      align-items: center;
      
      >div {
        margin-right: 1.25rem;
      }
    }
  }

  .table-container {
    padding: 0 2.5rem;

    .ant-table {
      box-shadow: 0 1px 4px rgba(0,0,0,.16);
      
      .ant-table-thead {
        background: #F8F9FB;

        .ant-table-cell {
          color: #757575;
          font-weight: bold;
        }
      }

      .ant-table-cell:nth-child(7) {
        text-align: right;
      }

      .active {
        .ant-table-cell:nth-child(6) {
          color: #019d1a;
        }
      }

      .inactive {
        .ant-table-cell:nth-child(6) {
          color: #fbb040;
        }
      }
    }

    .ant-table-pagination {
      display: none;
    }

    .table-name {
      display: flex;
      align-items: center;

      div:nth-child(1) {
        height: 3rem;
        width: 3rem;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        margin-right: 1rem;

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      div:nth-child(2) {
        h6 {
          font-size: .85rem;
          font-weight: bold;
          color: #373737;
          margin-bottom: .2rem;
        }

        p {
          margin: 0;
          font-size: .75rem;
          color: #757575;
        }
      }
    }
  }

  .pagination {
    margin: 2.5rem;

    > span {
      padding-top: 0.6rem;
      margin-left: 1.5rem;
      font-size: 0.875rem;
      color: #757575;
    }

    .ant-pagination {
      li {
        margin: 0;
        font-weight: bold;
        border-color: #eee;
        border-radius: 0;
        padding-top: .2125rem;
      }

      .ant-pagination-item {
        height: 2.5rem;
        width: 2.5rem;
      }

      .ant-pagination-item a {
        display: block;
        padding: 1px 6px;
        font-size: 0.875rem;
      }

      .ant-pagination-item-active {
        a {
          color: #fbb040;
        }
      }

      .ant-pagination-jump-next {
        min-width: 2.8rem;
        height: 2.2rem;
      }
      .ant-pagination-jump-prev {
        min-width: 2.8rem;
        height: 2.2rem;
      }

      .ant-pagination-prev {
        height: 2.5rem;
        width: 2.5rem;
        padding: 0;

        a {
          border-color: #eee;
          font-weight: bold;
          font-size: 0.8rem;
          padding: 1px 0px;
        }
      }

      .ant-pagination-next {
        height: 2.5rem;
        width: 2.5rem;
        padding: 0;

        a {
          border-color: #eee;
          font-weight: bold;
          font-size: 0.8rem;
          padding: 1px 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .questions {
    margin-left: 5rem;
  }
}

@import './addQuestions.scss';
@import './questionModule.scss';
@import './questionBuilder.scss';
@import './textChoice.scss';